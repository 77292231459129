import React from 'react';
import styled from 'styled-components';

import Layout from "../components/layout";
import SEO from "../components/seo";

import cemex from '../images/endorsment-logos/Grupo 58.svg'
import arnaiz from '../images/endorsment-logos/Grupo 59.svg'
import yanes from '../images/endorsment-logos/Grupo 61.svg'
import morganStanley from '../images/endorsment-logos/Grupo 62.svg'
import stJudeMedical from '../images/endorsment-logos/Grupo 63.svg'
import bbva from '../images/endorsment-logos/Grupo 64.svg'
import johnson from '../images/endorsment-logos/Grupo 65.svg'
import aCero from '../images/endorsment-logos/Grupo 66.svg'
import delitehabitat from '../images/endorsment-logos/Grupo 67.svg'
import cognolink from '../images/endorsment-logos/Grupo 68.svg'
import prognos from '../images/endorsment-logos/Grupo 69.svg'
import bainCo from '../images/endorsment-logos/Grupo 73.svg'
import ingelia from '../images/endorsment-logos/Grupo 74.svg'
import laCaixa from '../images/endorsment-logos/Grupo 75.svg'
import aura from '../images/endorsment-logos/Grupo 76.svg'
import cushmanWakefield from '../images/endorsment-logos/Grupo 77.svg'
import grupoCementosPortlandValderivas from '../images/endorsment-logos/Grupo 78.svg'
import wiwex from '../images/endorsment-logos/Grupo 79.svg'
import zed from '../images/endorsment-logos/Grupo 80.svg'
import santander from '../images/endorsment-logos/Grupo 81.svg'
import esade from '../images/endorsment-logos/Grupo 82.svg'
import diwberlin from '../images/endorsment-logos/Grupo 83.svg'
import oxfordAnalitica from '../images/endorsment-logos/Grupo 84.svg'
import pacasmayo from '../images/endorsment-logos/Grupo 85.svg'
import apollo from '../images/endorsment-logos/Grupo 99.svg'

const logos = [
  { src: apollo, alt: 'apollo' },
  { src: esade, alt: 'esade' },
  { src: bbva, alt: 'bbva' },
  { src: santander, alt: 'santander' },
  { src: cemex, alt: 'cemex' },
  { src: pacasmayo, alt: 'pacasmayo' },
  { src: oxfordAnalitica, alt: 'oxford analitica' },
  { src: arnaiz, alt: 'arnaiz' },
  { src: yanes, alt: 'yanes' },
  { src: morganStanley, alt: 'margan stanley' },
  { src: stJudeMedical, alt: 'st. jude medical' },
  { src: johnson, alt: 'johnson' },
  { src: aCero, alt: 'a-cero' },
  { src: delitehabitat, alt: 'delitehabitat' },
  { src: cognolink, alt: 'cognolink' },
  { src: prognos, alt: 'prognos' },
  { src: diwberlin, alt: 'diwberlin' },
  { src: bainCo, alt: 'bain&co' },
  { src: ingelia, alt: 'ingelia' },
  { src: laCaixa, alt: 'la caixa' },
  { src: aura, alt: 'aura' },
  { src: cushmanWakefield, alt: 'cushman & wakefield' },
  { src: grupoCementosPortlandValderivas, alt: 'grupo cementos portland valderivas' },
	{ src: wiwex, alt: 'wiwex' },
	{ src: zed, alt: 'zed' },
];

const Body = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  width: 80%;
  display: flex;
  flex-direction: column;
`;

const DescriptionSection = styled.section`
  text-align: left;
  padding-top: 161px;
  padding-bottom: 150px;
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-direction: row;

  & h2 {
    flex: 1 0 60%;
    font-family: 'Open Sans', sans-serif;
    font-size: 4rem;
    font-weight: 300;

    &:after {
      display: block;
      content: '';
      width: 56px;
      border-top: 4px solid white;
      margin-top: 24px;
    }
  }

  & p {
    flex: 1 0 40%;
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.531rem;
    max-width: 694px;
    margin: 0 auto;
    padding-bottom: 2%;
    width: 80%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0;
    padding-top: 64px;

    & h2 {
      font-size: 2.5rem;
      margin-bottom: 48px;
      text-align: left;

      &:after {
        margin-top: 8px;
      }
    }

    & p {
      font-size: 0.9375rem;
      line-height: 1.1875rem;
      text-align: left;
      margin: 0;
      width: 100%;
    }
  }
`;

// TODO: check with Oscar if we could get the correct canvas size for
//       svgs or... We could increment cell's height
const EndorsementGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 114px;
  align-items: center;
  justify-items: center;
  margin: 0 auto;
  max-width: 1440px;

  text-align: center;

  .endorsement-grid__item {
    width: 160px;

    img {
      height: 100%;
    }
  }

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);

    .endorsement-grid__item {
      width: 137px;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 64px;
    margin-top: 69px;
    margin-bottom: 120px;

    .endorsement-grid__item {
      width: 128px;
      grid-column: span 2;
    }

    .endorsement-grid__item:nth-last-child(1) {
      grid-column: 2 / span 2;
    }
  }

  @media (max-width: 425px) {
    grid-row-gap: 32px;

    .endorsement-grid__item {
      width: 110px;
    }
  }
`

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Endorsements" />
      <Body>
        <DescriptionSection>
          <h2>endorsements</h2>
          <p>
            Among our clients we count top international players within the banking,
            construction and medical industries, medium scale property and luxury
            goods retail related companies and several public and private think
            tanks.
          </p>
        </DescriptionSection>
        <section>
          <EndorsementGrid className='endorsment-grid'>
            { logos.map(item => (
                <div className='endorsement-grid__item'>
                  <img src={ item.src } alt={ item.alt }/>
                </div>
              )
            )}
          </EndorsementGrid>
        </section>
      </Body>
    </Layout>
  );
}

export default IndexPage
